import { config } from './config';

export function getSignalingUrl(peerId, roomId)
{
	const hostname = config.serverHostname || window.location.hostname;
	const port =
		process.env.NODE_ENV !== 'production' ?
			config.developmentPort
			:
			config.productionPort;

	const url = `wss://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}`;

	return url;
}

export function getRoomUrl(roomId)
{
	const hostname = config.serverHostname || window.location.hostname;
	const port =
		process.env.NODE_ENV !== 'production' ?
			config.developmentPort
			:
			config.productionPort;

	let url = `https://${hostname}/${roomId}`;

	if (port !== 443)
		url = `https://${hostname}:${port}/${roomId}`;

	return url;
}

export function getAppUrl(roomId, roomOwner)
{
	const hostname = config.serverHostname || window.location.hostname;
	const port =
		process.env.NODE_ENV !== 'production' ?
			config.developmentPort
			:
			config.productionPort;

	let url;

	if (roomOwner)
	{
		url = `https://app.${hostname}/elderly-screen/?user=${roomId}`;

		if (port !== 443)
			url = `https://app.${hostname}:${port}/elderly-screen/?user=${roomId}`;
	}
	else
	{
		url = `https://app.${hostname}/care-giver-screen/`;

		if (port !== 443)
			url = `https://app.${hostname}:${port}/care-giver-screen/`;
	}

	return url;
}

export function getServerUrl()
{
	const hostname = config.serverHostname || window.location.hostname;
	const port =
		process.env.NODE_ENV !== 'production' ?
			config.developmentPort
			:
			config.productionPort;

	let url = `https://${hostname}`;

	if (port !== 443)
		url = `https://${hostname}:${port}`;

	return url;
}
